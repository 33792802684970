<template>
  <el-dialog
    title="订单信息"
    :close-on-click-modal="false"
    :visible.sync="visible"
    class="missionPart"
    append-to-body
  >
    <el-form :model="dataForm" ref="dataForm" label-width="80px">
      <div class="tablePart">
        <el-form-item label="收货人姓名">
          <el-input v-model="dataForm.deliveryName" :disabled="true"></el-input>
        </el-form-item>

        <el-form-item label="收货人号码">
          <el-input
            v-model="dataForm.deliveryMobile"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货地址">
          <el-input
            v-model="dataForm.deliveryAddress"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="goodsList" border stripe ref="goodsList">
      <el-table-column
        prop="goodsName"
        header-align="center"
        align="center"
        label="商品名字"
      >
      </el-table-column>
      <el-table-column
        prop="goodsNumber"
        header-align="center"
        align="center"
        label="商品编号"
      >
      </el-table-column>
      <el-table-column
        prop="goodsQuantity"
        header-align="center"
        align="center"
        label="商品数量"
      >
      </el-table-column>
      <el-table-column
        prop="goodsUnitPrice"
        header-align="center"
        align="center"
        label="商品价格"
      >
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { dialogMixin, normal } from '@/mixins';
export default {
  mixins: [dialogMixin, normal],
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        deliveryName: '',
        deliveryMobile: '',
        deliveryAddress: '',
      },
      goodsList: [],
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      this.dataForm.id = id;
      this.$api({
        url: `/cm/shoppingrecord/findDetail/${this.dataForm.id}`,
        after: (data) => {
          if (data && data.code === 0) {
            this.dataForm = data.shoppingDetail;
            this.goodsList = data.shoppingDetail.shoppingGoodsList;
          } else {
            this.dataForm = [];
            this.goodsList = [];
          }
        },
      });
    },
  },
};
</script>
